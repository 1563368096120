@charset "UTF-8";
.fR {
  float: right;
}

.fL {
  float: left;
}

.clr {
  clear: both;
}

.scroll-sidebar {
  height: auto !important;
}

.bg-iri {
  background-color: #c46977 !important;
}

.btnIri {
  background-color: #c46977 !important;
  color: white !important;
}
.btnIri button {
  color: white !important;
}

.trace-search {
  border-radius: 20px;
}

.topbar .top-navbar .navbar-header {
  background-color: #fff !important;
}

.navbar-collapse {
  background-color: #c46977;
}

.logoProfile {
  padding: 20px;
}
.logoProfile a {
  margin-top: 20px;
}

input[type=radio] {
  margin-right: 10px;
}

.sidebar-nav ul {
  text-align: left;
}

.txtLeft {
  text-align: left;
}

.txtCenter {
  text-align: center;
}

.padRight50 {
  padding-right: 50px;
}

.padRight30 {
  padding-right: 30px;
}

.padTop30 {
  padding-top: 30px;
}

.padTop20 {
  padding-top: 20px;
}

.padTop10 {
  padding-top: 10px;
}

.padTop5 {
  padding-top: 5px;
}

.pr-0 {
  padding-right: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.marRight50 {
  margin-right: 50px;
}

.marRight20 {
  margin-right: 20px;
}

.marRight10 {
  margin-right: 10px;
}

.marTop50 {
  margin-top: 50px;
}

.breadcrumb-not-active {
    color: #cccccc;
}

.breadcrumb-item.breadcrumb-active {
    color: #000000 !important;
  font-weight: bold;
}

hr {
  box-sizing: content-box;
  height: 0;
  margin-top: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

h3.card-title {
  color: #8f3644;
}

.form-label { 
  color: #0d6efd!important;
}

.text-blue { 
  color: #0d6efd!important;
}


.mini-sidebar .sidebar-nav #sidebarnav li ul li a {
  color: red !important;
  height: 30px !important;
}

.mini-sidebar .sidebar-nav #sidebarnav > li:hover > ul.collapse li a {
  color: red !important;
  height: 30px !important;
}

.transparent {
  color: transparent !important;
}

.icon-button {
  cursor: pointer;
}

.icon-button:hover {
  opacity: 0.5;
}

.table-headers {
  flex: 1;
  flex-direction: row;
}

.table-header-title {
  cursor: pointer;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.table-header-title:hover > .sort-icon {
  position: relative;
  display: block;
  color: #8f3644;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.trace-table-container {
  background-color: #fff;
}

.trace-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.trace-table {
  background-color: #fff;
  max-height: 50vh !important;
  overflow-y: auto;
}

.trace-table-item {
  cursor: pointer;
}

.trace-table-item:hover {
  background-color: gray !important;
  opacity: 0.7;
}

.trace-selected-item > td {
  background-color: #c46977;
  color: #fff;
}

.sort-icon {
  font-size: 12px;
  color: #8f3644;
  display: none;
  margin-left: 5px;
}

.text-white {
  color: white;
}

a {
  text-decoration: none;
}

.enter-option-btn {
  height: 100;
  width: 200;
}

.btn-close > * {
  --bs-btn-close-color: white !important;
  color: white !important;
}

.disabled {
  opacity: 0.3 !important;
  cursor: not-allowed;
}

.center {
  justify-content: center;
  align-items: center;
}

label {
  font-size: 13px;
}

.bordered {
  border: 2px solid gray;
  border-radius: 3px;
}

.border-bottom-0 {
  border-bottom: none;
}

.card-title {
  position: relative;
  width: -moz-fit-content;
  width: fit-content;
  font-weight: 500;
  font-size: 16px;
  margin-right: auto;
  margin-left: auto;
}

.bordered-title {
  background-color: #fff;
  margin-top: -10px;
  padding-left: 10px;
  padding-right: 10px;
}

.text-12 {
  font-size: 12px;
}

.text-13 {
  font-size: 13px;
}

.text-14 {
  font-size: 14px;
}

.text-15 {
  font-size: 15px;
}

.form-input {
  margin-top: 15px;
}

.datasheet-body {
  background-color: #e8e2e2;
  font-family: "Space Grotesk", sans-serif;
  padding: 30px;
}

.container {
  min-height: 100vh;
  padding: 30px 50px 30px 50px;
  background-color: #fff;
}

.nestedContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.companies {
  display: flex;
  flex-direction: column;
  width: 39%;
}

.currentInput {
  width: 95%;
}

#photo {
  width: 50%;
}

#addBtn {
  background-color: green;
  margin-right: 5px;
}

#deleteBtn {
  background-color: red;
}

.container {
  min-height: 100vh;
  padding: 30px;
}

.header {
  width: 80%;
  margin: 0 auto;
  min-height: 10vh;
  background-color: #e8e2e2;
  padding: 30px;
  border-radius: 3px 3px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.resumeTitle {
  opacity: 0.6;
}

.headerTitle {
  margin-bottom: 15px;
}

.resumeImage {
  vertical-align: middle;
  width: 150px;
  height: 150px;
  border-radius: 50%;
}

.resumeBody {
  width: 80%;
  margin: 0 auto;
  padding: 30px;
  min-height: 80vh;
  /* border: 1px solid #e0e0ea; */
}

.resumeBodyTitle {
  margin-bottom: 5px;
}

.resumeBodyContent {
  text-align: justify;
  margin-bottom: 30px;
}

/* PdfModal.css */
.pdf-modal {
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  width: 100vw;
}

.pdf-content {
  background-color: white;
  width: 80%; /* Adjust the width as needed */
  padding: 20px;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  max-height: 80vh; /* Adjust the height as needed */
  overflow-y: auto;
}

.card-body {
  min-height: 60vh !important;
}/*# sourceMappingURL=dev.css.map */



.img-close {
  display: none !important;
  font-size: 18px !important;
}

.editor-image:hover .img-close {
display: block !important;
   /* max-height: '100%'; */
   /* min-width: 100vw; */
}

.tui-image-editor-header {
  display: none !important;
}

.tui-image-editor-help-menu{
  display: none !important;
}

.cursor-zoom-in {
  cursor: zoom-in !important; /* Set cursor to zoom icon */
}

.cursor-zoom-out {
  cursor: zoom-out !important; /* Set cursor to zoom icon */
}



.text-primary {
  color: #0D6EFD !important;
}
