html {
  scroll-behavior: smooth;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@import url(./css/styleMinified.css);

.left-sidebar{
  height: auto!important;
  min-height: 100%!important;
};



.tabs-vertical .nav-link.active{
  background: #fb9678;
  border: 0px;
  color: #fff!important;
};


.vtabs .tabs-vertical .nav-link {
    color: #343a40;
    margin-bottom: 10px;
    border: 0px;
    border-radius: 0.25rem 0 0 0.25rem
};

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; 
}

.box {
  background-color: #fff;
  padding: 20px;
  margin: 10px;
  text-align: left;
  height: 120px;
  box-shadow: inset 6px 3px 6px 3px #fff;
  margin-top: 1rem;
  border: 1px solid #ccc;
  border-left: 1px solid #000;
  overflow: auto;
  
  max-width: 500px; 
  max-height: 400px;
  overflow-x: auto; 
  overflow-y: auto; 
}



.title-text {
  color: blue;
  margin-bottom: -1rem;
  display: block; 
  text-align: left; 
  font-size: .9rem;
  margin-left: .7rem;
}

.calendar-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
  padding: 25px;
}

.calendar-container {
  flex: 1;
  max-width: calc(33.33% - 20px); /* Adjust this as needed */
  border: 1px solid red;
  border: none;
}

.calendar-lower {
  border: 1px solid #ccc;
  min-height: 80px;
  margin-bottom: 1rem;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  max-width: 95%;
}



.btn-btn {
  min-width: 215px;
  line-height: 1rem;
  margin: 5px;
  color: #000;
}

.button-group-container {
  display: flex;
  justify-content: center;
  color: #000;
  align-items: center;
}
  
.top {
  display: flex;
  align-items: center;
  align-content: center;
}

.top label {
  margin-right: 5px;
  color: blue;
  align-items: center;
  font-size: .8rem;
  font-weight: 500;
  justify-content: center;
  margin-top: 8px;
}

.btm-btn {
  margin-left: 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px 2px;
}

.left-buttons {
  display: flex;
  gap: 10px;
}

.right-button {
  padding: 2px;
  border-radius: 5px;
  outline: 1px solid red;
}

/* Media query for screens smaller than 768px */
@media only screen and (max-width: 768px) {
  .calendar-container {
    max-width: calc(50% - 20px); 
  }
  
  .button-group-container {
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  .responsive-span {
    text-align: right; 
  }
  .btn-btn {
    align-items: center;
    justify-content: center;
  }
  
  .left-buttons {
    align-items: left;
    justify-content: left;
    text-align: left;
  }
  
  .top {
    margin-top: 1rem;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
  .top label {
    color: red;
    font-size: .8rem;
  }
  
  .buttons-container {
    color: red;
  }
  
  #grp-btn {
    display: none;
    /* display: block; */
  }
}

@media only screen and (min-width: 768px) {
  .btn-btn {
    font-size: .7rem; 
    color: #000;
    align-items: center;
    justify-content: center;
  }

  .top label {
    color: blue;
  }
  
  .top {
    align-items: center;
    text-align: center;
    margin-left: 230px;
  }
  .buttons-container {
    color: blue;
  }
  #btns-grp {
    display: none;
  }
}

/* Media query for screens smaller than 480px */
@media (max-width: 480px) {
  .calendar-container {
    max-width: 100%;
  }
}

.letter-container {
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
}


.buttons-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
}

.lefts-buttons {
  display: flex;
  gap: 5px;
}

.right-button {
  /* Optional: Add margins or other styling for the right button */
}


.btns {
  font-size: .6rem;
  line-height: .5rem;
  padding: 10px 20px;
  background-color: #fff;
  color: #000;
  outline: 1px solid #ccc;
  border: none;
  cursor: pointer;
} 

#blank {
  color: #fff;
  border: none;
  border: transparent;
}



#grp-btn {
  text-align: left;
  align-items: left;
  justify-content: left;
  float: left;
  margin-right: -220px;
}

#ext-btn {
  margin-right: -435px;
  float: right;
  text-align: right;
  align-items: right;
  justify-content: right;
}


.cam-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin: 0 10px;
}

.cam-img {
  /* height: '100%'; */
  width: 100%;

  /* object-fit: cover; */
}


h3 {
  font-size: 15px !important;
}

.dx-theme-generic-typography h5 {
    font-weight: 400 !important;
    font-size: 15px !important;
}



.left-sidebar {
  /* width: 250px; */
  /* position: fixed; */
  /* top: 0; */
  /* left: 0px; */
  transition: left 0.3s ease-in-out;
}


@media (max-width: 768px) {
  .left-sidebar {
    left: -250px;
  }

  .left-sidebar.open {
    left: 0;
  }
  
}